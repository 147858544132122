import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 关于我们/api/getNavigationList

export function getSearchDetailNavi(data){
  return request({
      url:`${baseURL.api}/api/searchAboutUs`,
      method: "post",
      data
  });
}
